<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.deposit.deposittransactionlist')" :onSearch="onSearch">
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <CInput :label="$t('pages.deposit.payerName')" :placeholder="$t('pages.deposit.enterpayerName')" v-model="searchInfo.payerName" horizontal></CInput>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <CInput :label="$t('pages.bank.accountNo')" :placeholder="$t('pages.bank.accountNo')" v-model="searchInfo.accountNo" horizontal></CInput>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.status')}} </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="allDepositStatus"
                                                     v-model="searchInfo.selectedStatusList"
                                                     :searchable="false"
                                                     :close-on-select="false"
                                                     :clear-on-select="false"
                                                     :limit="10"
                                                     :multiple="true"                                                    
                                                     :placeholder="$t('common.picksome')"                                                     
                                                     :reset-after="false"
                                                     label="label"
                                                     track-by="key">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">

                            </CCol>
                        </CRow>


                    </CForm>


                </template>
                <template v-slot:list>
                    <CDataTable :items="depositList"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                @page-change="onPageChanged"
                                :loading="isLoading"
                                :items-per-page="itemsPerPage">
                        <template #createdDate_text="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                        <template #deposit_status="{item}">
                            <td class="py-2">
                                <!--<label v-text="depostStatus(item.status)" :color="depostStatusCss(item.status)" />-->
                                <CButton :class="depostStatusCss(item.status)" v-text="depostStatus(item.status)" />
                            </td>
                        </template>
                        <template #deposit_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onViewDetail(item, index)">
                                    {{ $t('common.detail') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                    <CPagination :pages="totalPages"
                                 :activePage="pageIndex + 1"
                                 @update:activePage="onPageChanged"></CPagination>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import DepositStatus from '@/helpers/DepositStatus'
    
    export default {
        name: 'DepositList',
        data() {
            return {                              
                allDepositStatus: [],
                itemsPerPage: 2,
                warningModal: false,
                fields: [
                    { key: 'id', label: i18n.t('pages.deposit.transactioncode') },                    
                    { key: 'payerName', label: i18n.t('pages.deposit.payerName') },
                    { key: 'accountNo', label: i18n.t('pages.bank.accountNo') },
                    { key: 'amount', label: i18n.t('common.amount') },
                    { key: 'createdDate_text', label: i18n.t('common.createdDate') },
                    { key: 'deposit_status', label: i18n.t('common.status') },  
                    //{ key: 'status', label: i18n.t('common.status') },
                    {
                        key: 'deposit_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },
        components: {
            ListForm,
            Confirmation
        },
        computed: {
            //...mapGetters('auth', ['user']),
            ...mapState('deposit', ['searchInfo', 'isLoading', 'depositList', 'totalPages', 'pageIndex'])
        },
        methods: {
            ...mapActions('deposit', ['searchDeposits']),

            formatDate(date) {               
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },
            depostStatus(iStatus) {

                if (DepositStatus.Completed === iStatus)
                    return i18n.t('common.completed');
                else if (DepositStatus.Rejected === iStatus)
                    return i18n.t('common.rejected');
                else if (DepositStatus.Canceled === iStatus)
                    return i18n.t('common.canceled');
                else
                    return i18n.t('common.pending');
               
            },
            depostStatusCss(iStatus) {
               
                if (DepositStatus.Rejected === iStatus || DepositStatus.Canceled === iStatus)
                    return "btn-warning";
                else if (DepositStatus.Completed === iStatus)
                    return "btn-success";
                else
                    return "btn-danger";
            },

            onViewDetail(item) {                
                this.$router.push('/assets/deposit-detail/' + item.id);
            },
            onSearch() {                
                this.searchDeposits();
            },
            onPageChanged(pageNo, reduced) {
                console.log('PageChanged', { pageNo, reduced });
                this.searchDeposits(pageNo);
            },
        },
       
        created() {
           
            this.searchInfo.selectedStatusList = [{ key: DepositStatus.Pending, label: i18n.t('common.pending') }];
            this.allDepositStatus = this.$depositService.getAllDepositStatusList();            

            this.onSearch();
        }
	}
</script>

